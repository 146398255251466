<template>
  <div class="pt-56">
    <v-container>
      <!-- 銀行卡表單 -->
      <v-form
        v-if="routerParams.type && routerParams.type==='bank'"
        ref="form"
        v-model="valid"
      >
        <v-row class="px-1">
          <!-- 4 -->
          <!-- 開戶姓名 -->
          <v-col
            cols="6"
            class="pt-4 pb-0"
          >
            <span class="title--text mb-1 subtitle-2">
              {{ $t('myCenter.bankAccountName') }}
            </span>
            <v-text-field
              v-model="form.defray_name"
              class="mt-2"
              min-height="36"
              disabled
              dense
              outlined
              required
            />
          </v-col>

          <!-- 開戶銀行 -->
          <v-col
            v-if="currencySymbol!=='INR'"
            cols="12"
            class="py-0"
          >
            <span class="title--text mb-1 subtitle-2">
              {{ $t('myCenter.bankName') }}
            </span>
            <v-text-field
              v-model="dispalyInput.bankName"
              class="mt-2"
              max-height="36"
              readonly
              dense
              outlined
              required
              :rules="[rules.required.bankCode]"
              :placeholder="$t('form.missBank')"
              @click="dialog.status = true"
            >
              <div
                v-if="activeBank"
                slot="prepend-inner"
                class="bank__inner d-flex align-center p-relative"
              />
              <div slot="append">
                <v-icon size="23">
                  mdi-chevron-right
                </v-icon>
              </div>
            </v-text-field>
          </v-col>

          <!-- 銀行分行(印度盧比顯示) -->
          <v-col
            v-if="currencySymbol==='INR'"
            cols="12"
            class="py-0"
          >
            <span class="title--text mb-1 subtitle-2">
              {{ $t('myCenter.bankNameIFSC') }}
            </span>
            <v-text-field
              v-model="form.ifsc_code"
              class="mt-2"
              max-height="36"
              dense
              outlined
              required
              :rules="[
                rules.required.bankNameIFSC,
                rules.ifsc.bankName,
              ]"
              :placeholder="$t('form.missBankNameIFSC')"
            />
          </v-col>

          <!-- 銀行卡號 -->
          <v-col
            cols="12"
            class="py-0"
          >
            <span class="title--text mb-1 subtitle-2">
              {{ $t('myCenter.bankCardNo') }}
            </span>
            <v-text-field
              v-model.trim="form.bank_no"
              class="mt-2"
              max-height="36"
              dense
              :placeholder="$t('form.missCardNo')"
              single-line
              outlined
              inputmode="numeric"
              required
              clear-icon="mdi-close-circle"
              clearable
              :rules="[
                rules.required.bankCard,
                rules.bankCard.minLength,
                rules.bankCard.maxLength,
              ]"
              @keyup="bankIdFormat"
            >
              <div slot="clear-icon">
                <v-icon>mdi-close-circle</v-icon>
              </div>
            </v-text-field>
          </v-col>

          <!-- 开户省市 (僅有 cn 需要選擇 「開戶省分」) -->
          <v-col
            v-if="nation === 'china' ? true : false"
            cols="12"
            class="py-0"
          >
            <span class="title--text mb-1 subtitle-2">
              {{ $t('myCenter.provinceCity') }}
            </span>
            <v-text-field
              class="mt-2"
              max-height="36"
              dense
              outlined
              required
              readonly
              :placeholder="$t('form.missProvince')"
              :value="bankCitiesInput"
              :rules="[rules.required.provinceNcity]"
              @click="showSheet"
            >
              <div slot="append">
                <v-icon size="23">
                  mdi-chevron-right
                </v-icon>
              </div>
            </v-text-field>
          </v-col>

          <v-col
            cols="12"
            class="py-0"
          >
            <v-btn
              block
              height="44"
              class="subtitle-1"
              color="primary"
              :disabled="!valid"
              @click="submitForm('form')"
            >
              {{ $t('global.save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>

      <!-- 虛擬幣表單 -->
      <v-form
        v-if="routerParams.type && routerParams.type==='usdt'"
        ref="usdtForm"
        v-model="valid"
      >
        <v-row class="px-1">
          <!-- 別名 -->
          <v-col
            cols="12"
            class="pt-4 pb-0"
          >
            <span class="title--text mb-1 subtitle-2">
              {{ $t('myCenter.alias') }}
            </span>
            <v-text-field
              v-model="usdtForm.slug"
              class="mt-2"
              min-height="36"
              dense
              outlined
              required
              :rules="[usdtRules.required.slug]"
              :placeholder="$t('form.missSlug')"
            />
            <span class="font-12">{{ $t('form.slugInfo') }}</span>
          </v-col>

          <hr class="hr-divide">

          <!-- 虛擬幣種類 -->
          <v-col
            cols="12"
            class="py-0"
          >
            <span class="title--text mb-1 subtitle-2">
              {{ $t('myCenter.cryptoType') }}
            </span><br>
            <v-btn
              v-for="(c,i) in cryptoList"
              :key="i"
              depressed
              active-class="test"
              class="mt-2"
              color="card2"
              :style="c.code===usdtForm.bank_id ? activeItem : ''"
              @click="handleName(c.code)"
            >
              <span
                class="caption mt-1 font-weight-medium rounded-xl"
                :class="[
                  true
                    ? 'primary--text'
                    : 'subtitle--text',
                ]"
              >
                {{ c.name }}
              </span>
            </v-btn>
            <v-text-field
              v-model="usdtForm.bank_id"
              class="hidden-field"
              :rules="[usdtRules.required.bank_id]"
            />
          </v-col>

          <hr class="hr-divide">

          <!-- 虛擬幣協議 -->
          <v-col
            v-if="usdtForm.bank_id"
            cols="12"
            class="py-0"
          >
            <span class="title--text mb-1 subtitle-2">
              {{ $t('myCenter.cryptoProtocol') }}
            </span><br>
            <v-btn
              v-for="(p,i) in protocolList"
              :key="i"
              depressed
              active-class="test"
              class="mt-2 protocol-btn"
              color="card2"
              :style="p.code===usdtForm.protocol ? activeItem : ''"
              @click="handleProtocol(p.code)"
            >
              <span
                class="caption mt-1 font-weight-medium rounded-xl"
                :class="[
                  true
                    ? 'primary--text'
                    : 'subtitle--text',
                ]"
              >
                {{ p.name }}
              </span>
            </v-btn>
            <v-text-field
              v-model="usdtForm.protocol"
              class="hidden-field"
              :rules="[usdtRules.required.protocol]"
            />
          </v-col>

          <hr
            v-if="usdtForm.bank_id"
            class="hr-divide"
          >

          <!-- 錢包地址 -->
          <v-col
            cols="12"
            class="py-0"
            style="margin-bottom:14px"
          >
            <span class="title--text mb-1 subtitle-2">
              {{ $t("myCenter.cryptoAdress") }}
            </span>
            <v-text-field
              v-model.trim="usdtForm.bank_no"
              class="mt-2"
              max-height="36"
              dense
              :placeholder="$t('form.missWalletAdress')"
              single-line
              outlined
              inputmode="numeric"
              required
              clear-icon="mdi-close-circle"
              clearable
              :rules="[
                usdtRules.required.bank_no,
                usdtRules.usdt.adress,
              ]"
            >
              <div slot="clear-icon">
                <v-icon>mdi-close-circle</v-icon>
              </div>
            </v-text-field>
            <span class="font-12">{{ $t('myCenter.usdtAlert') }}</span><br><br>
          </v-col>

          <v-col
            cols="12"
            class="py-0"
          >
            <v-btn
              block
              height="44"
              class="subtitle-1"
              color="primary"
              :disabled="!valid"
              @click="submitForm('usdtForm')"
            >
              {{ $t('global.save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>

    <!-- 選擇銀行 Dailog -->
    <v-dialog
      v-model="dialog.status"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card class="bg">
        <div class="p-fixed w-100 bg">
          <v-toolbar
            class="bg"
          >
            <v-btn
              icon
              @click="closeSearchBankList"
            >
              <Icon
                data="@icon/toolbar/arrow_left.svg"
                width="16px"
                height="16px"
                class="icon--text"
              />
            </v-btn>
            <v-toolbar-title>
              {{ $t('form.missBank') }}
            </v-toolbar-title>
            <v-spacer />
          </v-toolbar>

          <!-- 搜尋銀行 -->
          <v-list
            three-line
            subheader
            class="bg"
          >
            <div class="search-area">
              <v-text-field
                id="searchInput"
                v-model.trim="dialog.searchKeyword"
                autofocus
                class="mx-4 mt-2 inputBg"
                hide-details
                :placeholder="$t('game.searchGame')"
                rounded
                autocomplete="off"
                dense
              >
                <!-- 放大鏡 -->
                <div
                  slot="prepend-inner"
                  class="mt-0"
                >
                  <v-btn
                    slot="btn"
                    icon
                  >
                    <Icon
                      data="@icon/search.svg"
                      width="24"
                      height="24"
                      class="white--text"
                    />
                  </v-btn>
                </div>
              </v-text-field>
            </div>
          </v-list>
        </div>

        <!-- 銀行列表 -->
        <v-list
          class="bg pt-110px"
          flat
        >
          <v-list-item-group
            color="primary"
          >
            <v-list-item
              v-for="bankItem in filterBankList"
              :key="bankItem._id"
              @click="setBankId(bankItem.code)"
            >
              <v-list-item-content>
                <v-list-item-title v-text="bankItem.name" />
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-dialog>

    <!-- 開戶省市 Sheet -->
    <v-bottom-sheet v-model="sheet.status">
      <v-sheet class="text-center">
        <v-list height="318px">
          <!-- province -->
          <template v-if="!form.bank_province">
            <v-subheader class="px-6">
              <span class="text-h6 title--text">
                {{ $t('myCenter.selectProvince') }}
              </span>
              <v-spacer />
              <v-icon
                color="title"
                @click="closeSheet"
              >
                mdi-close
              </v-icon>
            </v-subheader>
            <div class="overflow-auto h-318 pb-56 list">
              <v-list-item
                v-for="(item, province) in sheet.cities"
                :key="province"
                class="text-left px-6"
                @click="setProvince(province)"
              >
                <v-list-item-title>{{ province }}</v-list-item-title>
              </v-list-item>
            </div>
          </template>

          <!-- cities -->
          <template v-else>
            <v-subheader class="px-6">
              <v-icon
                class="mr-6"
                color="primary"
                @click="resetProvince"
              >
                mdi-chevron-left
              </v-icon>
              <span class="text-h6 title--text">
                {{ form.bank_province }}
              </span>
              <v-spacer />
              <v-icon
                color="title"
                @click="closeSheet"
              >
                mdi-close
              </v-icon>
            </v-subheader>
            <div class="overflow-auto h-318 pb-56 list">
              <v-list-item
                v-for="(city, province) in sheet.cities[form.bank_province]"
                :key="province"
                class="text-left px-6"
                @click="setCities(city)"
              >
                <v-list-item-title>{{ city }}</v-list-item-title>
              </v-list-item>
            </div>
          </template>
        </v-list>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import addCardMixin from '../mixins/addCardMixin'

export default {
  mixins: [addCardMixin],
}
</script>

<style lang="scss" scoped>
.bank {
	&__icon {
		display: inline-block;
		width: 18px;
		height: 18px;
	}
}

.h-318 {
	height: 318px;
}

.p-fixed {
	top: 0;
	z-index: 2;
}

.pt-110px {
	padding-top: 110px;
}
.font-12{
  font-size: 12px;
}
.protocol-btn+.protocol-btn{
  margin-left: 10px;
}
.hr-divide{
    width: 100%;
    margin: 10px 12px;
    border: none;
    height: 1px;
    color: #00000027;
    background-color: #00000027;
}
</style>
