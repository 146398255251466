import { mapActions, mapGetters, mapState } from 'vuex'
import { resStatus } from '@/utils/resUtils'
import { isValidateErc20, isValidateTrc20, isValidateIFSC } from '@/utils/validate'

export default {
  components: { },

  data: () => ({
    cryptoList: [],
    valid: true,
    routerParams: {},
    bankCode: '',
    form: {
      bank_id: '', // 銀行代碼
      ifsc_code: '', // 銀行分行(印度盧比)
      bank_no: '', // 銀行卡號，必須是唯一值，無法建立資料庫已有的卡號
      defray_name: '', // 開戶姓名
      phone: '', // 手機號碼
      bank_city: '', // 開戶市
      bank_province: '', // 開戶省
    },
    usdtForm: {
      slug: '', // 別名
      bank_id: 'usdt', // 虛擬幣種類
      protocol: '', // 虛擬幣協議
      bank_no: '', // 錢包地址
    },
    dispalyInput: {
      bankName: '',
    },
    dialog: {
      status: false,
      bankList: [],
      searchKeyword: '',
    },
    sheet: {
      status: false,
      cities: [],
    },
  }),

  computed: {
    ...mapState({
      formatNation: state => state.websiteData.phone_format_nation,
    }),
    ...mapGetters(['baseImgUrl', 'lang', 'nation', 'theme', 'currencySymbol']),
    /**
     * 取得主色色碼
     * @returns {string} 主色色碼
     */
    primaryColor() {
      return this.$vuetify.theme.themes[this.theme].primary
    },
    activeItem() {
      return {
        border: `1px solid ${this.primaryColor} !important`,
      }
    },
    protocolList() {
      return this.usdtForm.bank_id && this.cryptoList.length
        ? this.cryptoList.find(c => c.code === this.usdtForm.bank_id).protocols
        : []
    },

    activeBank() {
      return this.dialog.bankList.find(item => item.code === this.form.bank_id)
    },

    /**
     * 搜尋銀行 (篩選 關鍵字 搜尋的銀行)
     * @date 2021-10-20
     * @returns {array} 符合搜尋的銀行項目
     */
    filterBankList() {
      if (!this.dialog.searchKeyword) return this.dialog.bankList
      return this.dialog.bankList.filter(this.searchBankList)
    },

    // 銀行卡表單驗證
    rules() {
      return {
        required: {
          bankCard: value => !!value || this.$t('form.missCardNo'),
          bankCode: value => !!value || this.$t('form.missBank'),
          provinceNcity: value => !!value || this.$t('form.missProvince'),
          bankNameIFSC: value => !!value || this.$t('form.missBankNameIFSC'),
        },
        bankCard: {
          minLength: bank_no => {
            if (!bank_no) return false
            if (this.formatNation === 'thai') {
              // 不能 大於 10個字元 + 2個2字元空格 (泰國)
              return bank_no.length >= 14 || this.$t('form.errorCardNo')
            }
            // 不能 少於 16個字元 + 3個2字元空格 (中國)
            return bank_no.length >= 22 || this.$t('form.errorCardNo')
          },
          // 不能 大於 19字元 + 4個2字元空格
          maxLength: bank_no => {
            if (!bank_no) return false
            if (this.formatNation === 'thai') {
              // 不能 少於 10個字元 + 2個2字元空格 (泰國)
              return bank_no.length <= 14 || this.$t('form.errorCardNo')
            }
            return bank_no.length <= 27 || this.$t('form.errorCardNo')
          },
        },
        ifsc: {
          bankName: bank_no => {
            // 11碼、英文、數字 (印度盧比)
            return isValidateIFSC(bank_no) || this.$t('form.BankNameIFSCFail')
          },
        },
      }
    },

    // 虛擬幣表單驗證
    usdtRules() {
      return {
        required: {
          slug: value => !!value || this.$t('form.missSlug'),
          bank_id: value => !!value || this.$t('form.missId'),
          protocol: value => !!value || this.$t('form.missProtocol'),
          bank_no: value => !!value || this.$t('form.missWalletAdress'),
        },
        usdt: {
          adress: adress => {
            if (!adress) return false
            if (!this.usdtForm.protocol) return this.$t('form.protocolFirst')

            const validationMap = {
              trc20: isValidateTrc20,
              erc20: isValidateErc20,
            }
            const validationFunction = validationMap[this.usdtForm.protocol]
            if (this.usdtForm.bank_id === 'usdt' && validationFunction && !validationFunction(adress)) {
              return this.$t('form.walletAdressFail')
            }
            return true
          },
        },
      }
    },
    bankCitiesInput() {
      if (this.form.bank_province && this.form.bank_city) { return this.form.bank_province + '  ' + this.form.bank_city }
      return undefined
    },
  },

  watch: {
    // 重置搜尋關鍵字
    'dialog.status'(newStatus) {
      if (!newStatus) this.dialog.searchKeyword = ''
    },
    'form.bank_id'(newStatus) {
      // console.log(newStatus)
      this.form.bank_no = ''
      this.form.bank_city = ''
      this.form.bank_province = ''
    },
    'usdtForm.protocol'(newStatus) {
      // console.log(newStatus)
      this.usdtForm.bank_no = ''
    },
  },

  mounted() {
    this.routerParams = this.$route.query
    this.set_toolbar_title(this.routerParams.type && this.routerParams.type === 'usdt' ? this.$t('myCenter.addUsdtCard') : this.$t('flashMessage.addCard'))
    this.getBankList()
    this.getUserBaseInfo()
  },

  methods: {
    ...mapActions([
      'set_toolbar_title',
      'get_bank_list',
      'get_nation_city',
      'create_user_bank_card',
      'create_user_usdt_card',
      'get_user_base_info',
      'show_alert',
    ]),
    handleName(code) {
      // console.log(code)
      this.usdtForm.bank_id = code
    },
    handleProtocol(code) {
      // console.log(code)
      this.usdtForm.protocol = code
    },
    setBanckList(bankList) {
      this.dialog.bankList = bankList.banks
      this.cryptoList = bankList.cryptocurrencies
    },

    getUserBaseInfoSuccess({ member }) {
      this.form.phone = member.contact_no
      this.form.defray_name = member.username
    },

    async getUserBaseInfo() {
      const userBaseInfo = await this.get_user_base_info()
      resStatus(userBaseInfo, this.getUserBaseInfoSuccess)
    },

    async getBankList() {
      const bankList = await this.get_bank_list()
      resStatus(bankList, this.setBanckList)
    },

    setBankId(bankId) {
      this.form.bank_id = bankId
      this.dispalyInput.bankName = this.activeBank.name
      this.dialog.status = false
    },

    /**
     * 信用卡號格式化
     * @date 2021-03-29
     */
    bankIdFormat() {
      if (this.form.bank_id === 'usdt') return
      this.form.bank_no = this.form.bank_no
        .replace(/\D/g, '')
        .replace(/\s/g, '')
        .replace(/(\d{4})(?=\d)/g, '$1  ')
    },

    /**
     * 信用卡移除格式化
     * @date 2021-03-29
     * @param {string} bankId
     * @returns {string} 移除空格的信用卡號
     */
    bankIdRepalce(bankId) {
      return bankId.split('  ').join('')
    },

    /**
     * 設置語系方法
     * @date 2021-04-06
     * @param {string}} naion 國家名稱
     * @returns {object}} 國家省城資料
     */
    async getCityData(nation) {
      return await this.get_nation_city(nation)
    },

    listScrollToTop() {
      document.querySelector('.list').scrollTo(0, 0)
    },

    setProvince(province) {
      this.form.bank_province = province
      this.listScrollToTop()
    },

    resetProvince() {
      this.form.bank_province = ''
      this.listScrollToTop()
    },

    setCities(city) {
      this.form.bank_city = city
      this.sheet.status = false
    },

    /**
     * 開戶省份 (彈窗開啟)
     * @date 2021-07-06
     * @param {any} e
     * @param {string}} nation=this.nation 國家名稱
     */
    async showSheet(e, nation = this.nation) {
      this.sheet.status = true
      const citiesData = await this.getCityData(nation)
      this.sheet.cities = citiesData
    },

    /**
     * 開戶省份 (彈窗關閉)
     * @date 2021-07-06
     * @returns {any}
     */
    async closeSheet() {
      this.sheet.status = false
    },

    resetForm(formName) {
      this.$refs[formName].resetValidation()
      if (formName === 'form') {
        this.dispalyInput.bankName = ''
        Object.assign(this.form, this.$options.data().form)
        this.getUserBaseInfo()
      }
    },

    submitSuccess() {
      this.show_alert({
        icon: 'done',
        text: this.$t('global.save') + this.$t('global.success'),
      })
      setTimeout(() => {
        this.$router.replace({
          name: 'card',
          query: {
            type: this.routerParams.type || '',
          },
        })
      }
      , 1300)
    },

    submitFail() {
      this.show_alert({
        icon: 'fail',
        text: this.$t('global.save') + this.$t('global.fail'),
      })
    },

    /**
     * 發送表單 (新增卡片)
     * @date 2021-07-06
     */
    async submitForm(formName) {
      // formName若為form代表新增銀行卡,若為usdtForm則為新增虛擬幣帳戶
      this.$refs[formName].validate()
      if (formName === 'form') this.form.bank_no = this.bankIdRepalce(this.form.bank_no)
      if (this.currencySymbol === 'INR' && formName === 'form') this.form.bank_id = 'ifsc'
      this.$refs[formName].resetValidation()

      const res = formName === 'form'
        ? await this.create_user_bank_card(this.form)
        : await this.create_user_usdt_card(this.usdtForm)

      this.resetForm(formName)
      resStatus(res, this.submitSuccess, this.submitFail)
    },

    /**
     * 更新彈窗組件狀態 (監聽元件更新事件)
     * @date 2021-09-23
     * @param {any} newStatus
     */
    updaDialog(newStatus) {
      this.dialog.status = newStatus
    },

    /**
     * 搜尋銀行功能
     * @date 2021-10-20
     * @param {object} bankItem 每個銀行項目
     * @returns {boolean} 是否符合搜尋條件
     */
    searchBankList(bankItem) {
      const bankCode = bankItem.code
      const bankName = bankItem.name
      if (bankCode.includes(this.dialog.searchKeyword)) return true
      if (bankName.includes(this.dialog.searchKeyword)) return true
    },

    closeSearchBankList() {
      this.dialog.status = false
    },
  },
}
